import { generateClient } from "aws-amplify/api";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Comment } from "../API";
import { createComment } from "../graphql/mutations";
import { commentByChannel } from "../graphql/queries";
import { onCreateComment } from "../graphql/subscriptions";

const API = generateClient();

export default function Comments({
  config,
}: Readonly<{
  config: {
    title?: string;
    disabled?: boolean;
    keepCommentsForS?: number;
    channel?: string;
    texts?: {
      noComment?: string;
      typeHere?: string;
      send?: string;
    };
  };
}>) {
  const [input, setInput] = useState("");
  const [comments, setComments] = useState<Comment[]>([]);
  const bottomCommentElement = useRef<HTMLDivElement>(null);

  useLayoutEffect(
    () => bottomCommentElement.current?.scrollIntoView({ behavior: "smooth" }),
    [comments]
  );

  useEffect(() => {
    const dateLimit = new Date(
      Date.now() - (config.keepCommentsForS ?? 24 * 3600) * 1000
    );

    API.graphql({
      query: commentByChannel,
      variables: {
        channel: config.channel ?? "comments",
        createdAt: {
          gt: dateLimit.toISOString(),
        },
        limit: 500,
      },
    })
      .then(({ data }) => data.commentByChannel.items)
      .then(setComments);

    const subscription = API.graphql({
      query: onCreateComment,
      variables: {
        filter: {
          channel: { eq: config.channel ?? "comments" },
        },
      },
    }).subscribe(({ data }) =>
      setComments((current) => current.concat([data.onCreateComment]))
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, [config.keepCommentsForS, config.channel]);

  const onSubmit = useCallback(
    () =>
      API.graphql({
        query: createComment,
        variables: {
          input: {
            message: input,
            channel: config.channel ?? "comments",
          },
        },
      }).then(() => setInput("")),
    [input, config.channel]
  );

  return (
    <div className="flex h-fit w-full flex-col">
      <div className="max-h-80 w-full overflow-y-scroll rounded-md border-[1px] border-gray-700 px-3 py-1">
        {comments.length <= 0 && (
          <p>{config.texts?.noComment ?? "No comment yet."}</p>
        )}
        {comments.map((comment) => (
          <p key={comment.id}>
            {comment.message}{" "}
            <span className="text-xs text-gray-500">
              {moment(comment.createdAt).calendar()}
            </span>
          </p>
        ))}

        <div className="clear-both" ref={bottomCommentElement} />
      </div>
      <form
        className="mt-2 flex text-lg"
        onSubmit={(ev) => {
          ev.preventDefault();
          onSubmit();
        }}
      >
        <input
          type="text"
          disabled={config.disabled}
          className="w-full rounded-l-md px-1 text-black"
          value={input}
          onChange={(ev) => setInput(ev.target.value)}
          placeholder={config.texts?.typeHere ?? "Type here."}
        />
        <button
          type="submit"
          disabled={config.disabled}
          className="rounded-r-md border-[1px] border-white px-3"
        >
          {config.texts?.send ?? "Send"}
        </button>
      </form>
    </div>
  );
}
