import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { ActionFunctionArgs, Link, useFetcher } from "react-router-dom";
import FormButton from "../../components/FormButton";
import FormInput from "../../components/FormInput";

enum State {
  Init = "init",
  CodeSent = "codesent",
  Done = "done",
}

type ActionType = { error?: string; state?: State; email?: string };
export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionType> {
  const fields = Object.fromEntries(await request.formData());

  if (fields.intent !== State.Init && fields.intent !== State.CodeSent)
    throw new Response("", {
      status: 400,
      statusText: "Invalid Intent",
    });

  if (typeof fields.email !== "string" || !fields.email.includes("@"))
    return { error: "Invalid Email", state: State.Init };

  if (fields.intent === State.Init) {
    try {
      await resetPassword({ username: fields.email });
      return { state: State.CodeSent, email: fields.email };
    } catch (error: any) {
      return {
        error: error.message ?? "Unknown error",
        state: State.Init,
        email: fields.email,
      };
    }
  }

  // state is CodeSent
  if (typeof fields.code !== "string" || !fields.code)
    return {
      error: "Invalid Code",
      state: State.CodeSent,
      email: fields.email,
    };
  if (typeof fields.password !== "string" || !fields.code)
    return {
      error: "Invalid Password",
      state: State.CodeSent,
      email: fields.email,
    };

  try {
    await confirmResetPassword({
      username: fields.email,
      confirmationCode: fields.code,
      newPassword: fields.password,
    });
    return { state: State.Done };
  } catch (error: any) {
    return {
      error: error.message ?? "Unknown error",
      state: State.CodeSent,
      email: fields.email,
    };
  }
}

export default function ForgotPassword() {
  const fetcher = useFetcher();
  const {
    error,
    state = State.Init,
    email,
  } = (fetcher.data ?? {}) as ActionType;

  return (
    <fetcher.Form method="post">
      <h1 className="px-5 pt-5 text-4xl">Reset Password</h1>
      <p className="mx-5 mt-2">
        {state === State.Init &&
          "Please enter your email address. A code will be sent to your inbox to reset your password."}
        {state === State.Done &&
          "Your password has been reset. You can now login."}
        {state === State.CodeSent && "A code has been sent to your inbox."}
      </p>
      {error && <p className="mx-5 border-2 border-red-500 p-2">{error}</p>}

      <div className="m-5 text-lg">
        {state !== State.Done && (
          <FormInput
            label="Email"
            name="email"
            type="email"
            className={email ? "hidden" : ""}
          />
        )}

        {state === State.CodeSent && (
          <>
            <FormInput label="Code" name="code" type="text" />
            <FormInput label="Password" name="password" type="password" />
          </>
        )}
      </div>

      <div className="my-10 flex w-full">
        {state === State.Done ? (
          <Link
            to="/login"
            className="m-auto rounded-md bg-gray-300 px-5 py-2 text-lg text-black hover:bg-gray-400 active:bg-gray-500 disabled:bg-neutral-600"
          >
            Back to Login
          </Link>
        ) : (
          <FormButton
            label={(() => {
              if (fetcher.state !== "idle") return "Loading";
              if (state === State.Init) return "Reset password";
              return "Submit";
            })()}
            disabled={fetcher.state !== "idle"}
            value={state.toString()}
            name="intent"
          />
        )}
      </div>
    </fetcher.Form>
  );
}
