import React from "react";
import { Outlet, redirect } from "react-router-dom";
import { isLoggedIn } from "../../utils/auth";

export async function loader() {
  if (await isLoggedIn()) throw redirect("/");
  return {};
}

export default function AuthLayout() {
  return (
    <div className="flex">
      <div className="mx-auto my-auto w-5/6 lg:w-1/4">
        <Outlet />
      </div>
    </div>
  );
}
