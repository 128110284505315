import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "../assets/logo.png";
import { isLoggedIn } from "../utils/auth";

export default function MainLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [logged, setLogged] = useState(false);
  useEffect(() => {
    isLoggedIn().then(setLogged);
  }, []);

  return (
    <div className="h-full overflow-y-scroll py-8">
      <Link to="/" className="my-10 flex h-40 w-full flex-col p-5 talllg:h-1/6">
        <img
          src={logo}
          alt="AHX.AI Logo"
          className="mx-auto my-auto max-h-full"
        />
      </Link>

      {children ?? <Outlet />}

      {logged && (
        <div className="fixed bottom-0 flex w-full bg-bg p-2">
          <Link
            to="/logout"
            className="mx-auto my-auto border-[1px] border-white px-2 py-1 hover:bg-neutral-500"
          >
            Logout
          </Link>
        </div>
      )}
    </div>
  );
}

MainLayout.defaultProps = {
  children: undefined,
};
