import { generateClient } from "aws-amplify/api";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { listDataBlobs } from "../graphql/queries";
import { onUpdateDataBlob } from "../graphql/subscriptions";

const API = generateClient();

export type DataBlobsType = {
  [id: string]: any;
};

export type DataContextType = {
  getValue: (searchString: string) => any;
  getNumber: (searchString: string) => number;
  getNumbersArray: (searchString: string) => number[] | undefined;
  updateTimestamp: number;
};

export const dataContext = createContext<DataContextType>({
  getValue: () => undefined,
  getNumber: () => Number.NaN,
  getNumbersArray: () => undefined,
  updateTimestamp: 0,
});

export default function DataContextProvider({
  config,
  client,
  children,
}: Readonly<{
  config: string[];
  client: string;
  children: JSX.Element | JSX.Element[];
}>) {
  const [dataBlobs, setDataBlobs] = useState<DataBlobsType>({});
  const [subscriptionRestartCounter, setSubscriptionRestartCounter] =
    useState(0);
  const [updateTimestamp, setUpdateTimestamp] = useState(0);

  useEffect(() => {
    if (!config || config.length <= 0) return () => {};

    API.graphql({
      query: listDataBlobs,
      variables: {
        filter: {
          or: config.map((id) => ({ id: { eq: id } })),
        },
      },
    })
      .then(({ data }) =>
        data.listDataBlobs.items
          .filter((b) => b.client === client)
          .reduce((acc, v) => ({ ...acc, [v.id]: JSON.parse(v.blob) }), {})
      )
      .then(setDataBlobs);

    const subscription = API.graphql({ query: onUpdateDataBlob }).subscribe({
      next({ data }) {
        const db = data.onUpdateDataBlob;

        if (!config.includes(db.id) || db.client !== client) return;

        setDataBlobs((current) => ({
          ...current,
          [db.id]: JSON.parse(db.blob),
        }));
      },
      error(err) {
        console.error(`Error on subscribe #${subscriptionRestartCounter}`, err); // eslint-disable-line no-console
        setTimeout(() => {
          setSubscriptionRestartCounter(subscriptionRestartCounter + 1);
        }, 5000);
      },
    });

    return () => {
      subscription?.unsubscribe?.();
    };
  }, [config, client, subscriptionRestartCounter]);

  useEffect(() => setUpdateTimestamp(Date.now()), [dataBlobs]);

  const getValue = useCallback(
    (searchString: string) => {
      const [blobId, left] = searchString.split("$");
      if (!Object.hasOwn(dataBlobs, blobId)) return null;

      const path = left.split("&");
      let ret = dataBlobs[blobId];
      path.forEach((p) => {
        if (!ret) return;
        ret = Object.hasOwn(ret, p) ? ret[p] : null;
      });

      return ret;
    },
    [dataBlobs]
  );

  const getNumber = useCallback(
    (searchString: string) => Number.parseFloat(getValue(searchString)),
    [getValue]
  );

  const getNumbersArray = useCallback(
    (searchString: string) => {
      const values = getValue(searchString);
      if (!values || !Array.isArray(values)) return undefined;

      return values.map(Number.parseFloat);
    },
    [getValue]
  );

  const value = useMemo(
    () => ({
      getValue,
      getNumber,
      getNumbersArray,
      updateTimestamp,
    }),
    [getValue, getNumber, getNumbersArray, updateTimestamp]
  );
  return <dataContext.Provider value={value}>{children}</dataContext.Provider>;
}
