import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import awsconfig from "./aws-exports";
import "./global.css";
import AuthLayout, {
  loader as authLayoutLoader,
} from "./pages/auth/AuthLayout";
import LoginPage, { action as loginAction } from "./pages/auth/LoginPage";
import LogoutPage, { action as logoutAction } from "./pages/auth/LogoutPage";
import PasswordResetPage, {
  action as passwordResetAction,
} from "./pages/auth/PasswordResetPage";
import ClientSelectorPage, {
  loader as clientSelectorLoader,
} from "./pages/ClientSelectorPage";
import ErrorPage from "./pages/ErrorPage";
import OperatorApp, { loader as operatorLoader } from "./pages/OperatorApp";
import MainLayout from "./pages/MainLayout";
import reportWebVitals from "./reportWebVitals";

Amplify.configure(awsconfig);

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <ClientSelectorPage />,
        loader: clientSelectorLoader,
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: "login",
            element: <LoginPage />,
            action: loginAction,
            loader: authLayoutLoader,
          },
          {
            path: "passwordreset",
            element: <PasswordResetPage />,
            action: passwordResetAction,
            loader: authLayoutLoader,
          },
          {
            path: "logout",
            element: <LogoutPage />,
            action: logoutAction,
          },
        ],
      },
    ],
  },
  {
    path: "operator/:clientId",
    element: <OperatorApp />,
    loader: operatorLoader,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="flex min-h-screen flex-col text-white talllg:h-screen">
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);

reportWebVitals();
