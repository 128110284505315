import React, { useEffect, useMemo, useState } from "react";
import { Tab } from "@headlessui/react";
import Format from "./Format";
import useIdle from "../utils/useIdle";

export type TabElement = {
  title: string;
  element: JSX.Element;
  rotateTimerSeconds?: number;
};
export default function Tabs({
  defaultTab,
  children,
  className,
  selectedTab,
  setSelectedTab,
  idleTimeS,
}: {
  defaultTab?: number;
  children: (
    tab: (
      title: string,
      element: JSX.Element,
      rotateTimerSeconds?: number
    ) => TabElement
  ) => TabElement | undefined | (TabElement | undefined)[];
  className?: string;
  selectedTab?: number;
  setSelectedTab?: (index: number) => void;
  idleTimeS?: number;
}) {
  const [st, sst] = useState(defaultTab ?? 0);
  const realSelectedTab = selectedTab ?? st;
  const setRealSelectedTab = setSelectedTab ?? sst;

  const list = useMemo(() => {
    const a = children((title, element, rotateTimerSeconds) => ({
      title,
      element,
      rotateTimerSeconds,
    }));

    return (Array.isArray(a) ? a : [a]).filter((e): e is TabElement => !!e);
  }, [children]);

  const idle = useIdle((idleTimeS ?? 15) * 1000);
  useEffect(() => {
    if (!idle) return () => {};

    let next = realSelectedTab;
    let rotateTimerSeconds: number | undefined;

    do {
      next = list.length <= next + 1 ? 0 : next + 1;
      rotateTimerSeconds = list[next].rotateTimerSeconds;
    } while (!rotateTimerSeconds && next !== realSelectedTab);

    if (rotateTimerSeconds) {
      const timeout = setTimeout(
        () => setRealSelectedTab(next),
        rotateTimerSeconds * 1000
      );
      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [idle, realSelectedTab, list, setRealSelectedTab]);

  return (
    <Tab.Group
      selectedIndex={realSelectedTab}
      onChange={(v: any) => setRealSelectedTab(typeof v === "number" ? v : 0)}
      as="div"
      className={["flex flex-col", className].join(" ")}
    >
      <Tab.List>
        {list.map(({ title, element }) => (
          <Tab
            key={element.key}
            className={({ selected }) =>
              [
                "mr-1 rounded-t-md px-3 py-1 text-sm",
                selected ? "bg-primary" : "bg-highlight",
              ].join(" ")
            }
          >
            <Format str={title} />
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="min-h-0 flex-grow">
        {list.map(({ element }) => (
          <Tab.Panel
            className="h-full rounded-b-md rounded-tr-md bg-primary p-2"
            key={element.key}
          >
            {element}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}

Tabs.defaultProps = {
  defaultTab: undefined,
  className: undefined,
  selectedTab: undefined,
  setSelectedTab: undefined,
  idleTimeS: undefined,
};
