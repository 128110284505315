import React, { useCallback, useContext, useMemo } from "react";
import { dataContext } from "./DataContextProvider";
import { formatter } from "./Format";

export default function InspectionTable({
  selected,
  config,
}: {
  selected: [string, number] | undefined;
  config: {
    enabled: boolean;
    headers?: string[];
    emptyTableText?: string;
    variables?: {
      [key: string]: {
        name: string;
        valueArray?: string;
        liveValue?: string;
        unit?: string;
        decimals?: number;
      }[];
    };
  };
}) {
  const { getValue } = useContext(dataContext);

  const [inspectionType, index] = useMemo(
    () => selected ?? ["", 0],
    [selected]
  );

  const getIndexValueForSearch = useCallback(
    (search: string, decimals: number) => {
      const arr = getValue(search);
      if (arr && Array.isArray(arr) && arr.length > index) {
        if (typeof arr[index] !== "number") return arr[index];
        if (!Number.isNaN(arr[index])) return arr[index].toFixed(decimals);
      }

      return "";
    },
    [getValue, index]
  );

  const getCurrentValue = useCallback(
    (search: string, decimals: number) => {
      const r = formatter(search, getValue);
      if (!r) return "";
      const rAsN = Number(r);
      if (!Number.isNaN(rAsN)) return rAsN.toFixed(decimals);
      return r;
    },
    [getValue]
  );

  if (!selected)
    return (
      <p>{config.emptyTableText ?? "Please click on a historical point"}</p>
    );

  return (
    <div className="h-full w-full overflow-y-auto rounded-md border-[1px] border-gray-700">
      <table className="h-full w-full table-fixed text-center lg:table-auto lg:text-lg">
        {config.headers && (
          <thead>
            <tr className="sticky top-0 break-words bg-primary shadow-sm shadow-gray-700">
              {config.headers.map((h) => (
                <th key={h} className="px-1 first:text-left">
                  {h}
                </th>
              ))}
            </tr>
          </thead>
        )}

        {config.variables && (
          <tbody>
            {config.variables[inspectionType].map((variable) => (
              <tr key={variable.name} className="break-words odd:bg-highlight">
                <td className="px-1 text-left">{variable.name}</td>

                <td className="px-1">
                  {getIndexValueForSearch(
                    variable.valueArray ?? "",
                    variable.decimals ?? 2
                  )}
                </td>

                <td className="px-1">
                  {getCurrentValue(
                    variable.liveValue ?? "",
                    variable.decimals ?? 2
                  )}
                </td>

                <td className="px-1">{variable.unit}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
}
