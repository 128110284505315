import React from "react";
import { useRouteError } from "react-router-dom";
import MainLayout from "./MainLayout";

export default function ErrorPage() {
  const error: any = useRouteError();

  return (
    <MainLayout>
      <div className="flex w-full flex-col">
        <h1 className="mx-auto text-4xl font-bold">Oops!</h1>
        <p className="mx-auto mb-2 mt-5">Sorry, an error has occurred.</p>
        <p className="mx-auto italic">{error.statusText || error.message}</p>
      </div>
    </MainLayout>
  );
}
