import React from "react";

export default function FormButton({
  disabled,
  label,
  name,
  value,
  className,
}: {
  disabled?: boolean;
  label?: string;
  name?: string;
  value?: string;
  className?: string;
}) {
  return (
    <button
      type="submit"
      disabled={disabled}
      name={name}
      value={value}
      className={[
        "m-auto bg-action px-5 py-2 text-lg hover:bg-primary active:bg-gray-500 disabled:bg-neutral-600",
        className,
      ].join(" ")}
    >
      {label}
    </button>
  );
}

FormButton.defaultProps = {
  label: "Submit",
  disabled: false,
  name: undefined,
  value: undefined,
  className: undefined,
};
