import React, { useContext } from "react";
import { dataContext } from "./DataContextProvider";

export default function Table({
  config: { search, colorSearch, sizeSearch },
}: {
  config: {
    search?: string;
    colorSearch?: string;
    sizeSearch?: string;
  };
}) {
  const { getValue } = useContext(dataContext);
  if (!search) return <p>Empty table</p>;

  const matrix: { [key: string]: Array<string> } = getValue(search);
  if (!matrix) return <p>No Data</p>;

  const colorMatrix: { [key: string]: Array<string> } = colorSearch
    ? getValue(colorSearch)
    : null;

  const sizeMatrix: { [key: string]: Array<number> } = sizeSearch
    ? getValue(sizeSearch)
    : null;

  return (
    <div className="h-full w-full overflow-y-auto rounded-md border-[1px] border-gray-700">
      <table className="h-full w-full table-fixed text-center lg:table-auto lg:text-lg">
        <tbody>
          {Object.keys(matrix)
            .filter((key) => Array.isArray(matrix[key]))
            .map((key) => (
              <tr key={key} className="break-words even:bg-highlight">
                {matrix[key].map((cell, j) => (
                  <th
                    // Disabling this eslint rule as the array dimensions should never change.
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${key}-${j}`}
                    className="px-1"
                    style={{
                      color: colorMatrix ? colorMatrix[key][j] : "",
                      fontSize: sizeMatrix
                        ? `${sizeMatrix[key][j] * 1.125}rem`
                        : "",
                    }}
                  >
                    {cell}
                  </th>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
