import { generateClient } from "aws-amplify/api";
import { Link, generatePath, redirect, useLoaderData } from "react-router-dom";
import { listAppParameters } from "../graphql/queries";
import { isLoggedIn } from "../utils/auth";

const API = generateClient();

export async function loader() {
  if (!(await isLoggedIn())) throw redirect("/login");

  const { data } = await API.graphql({
    query: listAppParameters,
    variables: {
      filter: {
        key: { eq: "clientInfo" },
      },
    },
  });

  if (data.listAppParameters.items.length === 1)
    throw redirect(
      generatePath("operator/:clientId", {
        clientId: data.listAppParameters.items[0]?.client ?? "error",
      })
    );

  return {
    clientList: data.listAppParameters.items.map((a) => ({
      id: a.client ?? "error",
      ...JSON.parse(a.value ?? ""),
    })),
  };
}

export default function ClientSelectorPage() {
  const { clientList } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  return (
    <div className="flex w-full flex-col space-y-5">
      <p className="mx-auto">
        Please select the profile you would like to use.
      </p>

      {clientList.length <= 0 && (
        <p className="mx-auto text-xl font-bold">
          You don&apos;t have any profile yet. Contact us.
        </p>
      )}

      <div className="m-3 flex flex-wrap justify-around">
        {clientList.map((client) => (
          <Link
            key={client.id}
            className="m-2 flex h-28 w-28 bg-primary p-2 md:h-44 md:w-44"
            to={generatePath("operator/:clientId", { clientId: client.id })}
          >
            <h2 className="mx-auto my-auto text-center font-bold">
              {client.name}
            </h2>
          </Link>
        ))}
      </div>
    </div>
  );
}
