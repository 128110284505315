import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import isSameDay from "../utils/isSameDay";
import isValidNumber from "../utils/isValidNumber";
import useIdle from "../utils/useIdle";
import { dataContext } from "./DataContextProvider";
import Format from "./Format";

// When the last update is older than EXPIRATION_DELAY_MS ms we are considered out of sync
const EXPIRATION_DELAY_MS = 5 * 60 * 1000;
const IDLE_DELAY_MODAL_MS = 5 * 60 * 1000;
const DISABLE_MODAL = false;

export default function Header({
  className,
  config,
}: {
  className?: string;
  config?: {
    headerText?: string;
    logoutText?: string;
  };
}) {
  const { getValue } = useContext(dataContext);
  const idle = useIdle(IDLE_DELAY_MODAL_MS);
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>();
  const [isConnected, setIsConnected] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (idle && !isConnected) setShowModal(true);
  }, [idle, isConnected]);

  useEffect(() => {
    const timeout = setTimeout(
      () => setShowModal(!isConnected),
      isConnected ? 0 : 5000
    );
    return () => clearTimeout(timeout);
  }, [isConnected]);

  useEffect(() => {
    const timestamp = getValue("realtime$time");
    if (!isValidNumber(timestamp)) return () => {};
    setLastUpdate(new Date(timestamp * 1000));

    const expiresIn = timestamp * 1000 + EXPIRATION_DELAY_MS - Date.now();
    setIsConnected(expiresIn > 0);
    if (expiresIn > 0) {
      const timeout = setTimeout(() => setIsConnected(false), expiresIn);
      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [getValue]);

  return (
    <div
      className={[
        "relative flex w-full flex-wrap justify-between bg-primary px-2 py-2 lg:h-12",
        className,
      ].join(" ")}
    >
      <Link to="/" className="flex w-1/4 lg:h-full lg:w-auto lg:p-1">
        <img src={logo} alt="AHX.AI Logo" className="my-auto lg:h-full" />
      </Link>

      <div className="flex w-3/4 lg:w-auto">
        {config?.headerText && (
          <p className="mx-3 my-auto text-xl">
            <Format str={config.headerText} />
          </p>
        )}

        <div className="group relative z-50 my-auto">
          <div className="relative my-auto h-6 w-6">
            {!isConnected && (
              <div className="absolute h-full w-full rounded-full bg-red-600" />
            )}
            <div
              className={[
                "absolute h-full w-full rounded-full",
                isConnected
                  ? "animate-pulse bg-green-400"
                  : "animate-ping bg-red-600",
              ].join(" ")}
            />
          </div>

          <div className="absolute right-1 top-7 hidden h-4 w-4 rotate-45 bg-black group-hover:block" />
          <div className="absolute -right-2 mt-2 hidden w-max bg-black p-2 text-white group-hover:block">
            <p className="text-center">
              {isConnected ? "Up to Date" : "Out of Sync"}
            </p>
            <p>
              Last update:{" "}
              {lastUpdate
                ? lastUpdate.toLocaleString(undefined, {
                    dateStyle: isSameDay(lastUpdate, new Date())
                      ? undefined
                      : "short", // Don't show date if last update in the same day
                    timeStyle: "medium",
                  })
                : "-"}
            </p>
          </div>
        </div>

        <Transition appear show={showModal && !DISABLE_MODAL}>
          <Dialog onClose={() => setShowModal(false)} className="relative z-40">
            <Transition.Child
              enter="transform transition duration-1000 ease-in-out"
              enterFrom="-translate-y-[100vh] opacity-0"
              enterTo="translate-y-0 opacity-100"
              as={Fragment}
            >
              <Dialog.Backdrop
                className="fixed inset-0 bg-black/50"
                aria-hidden="true"
              />
            </Transition.Child>

            <div className="fixed inset-0 flex w-screen items-center justify-center">
              <Transition.Child
                enter="transform transition duration-1000 ease-in-out"
                enterFrom="-translate-y-[100vh]"
                enterTo="translate-y-0"
                as={Fragment}
              >
                <Dialog.Panel className="relative w-5/6 max-w-lg rounded-lg bg-highlight p-3 text-center text-white lg:w-full">
                  <Dialog.Title className="text-xl font-extrabold">
                    Out of Sync
                  </Dialog.Title>
                  <button
                    type="button"
                    className="absolute right-3 top-2 h-5 w-5 before:absolute before:right-0 before:top-1/2 before:h-1 before:w-5 before:-rotate-45 before:bg-primary after:absolute after:right-0 after:top-1/2 after:h-1 after:w-5 after:rotate-45 after:bg-primary before:hover:bg-secondary after:hover:bg-secondary"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  />
                  <Dialog.Description>
                    Could not retrieve up to date information. This could be due
                    to a connection issue or an outage on the backend.
                  </Dialog.Description>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>

        <Link
          to="/"
          className="my-auto ml-3 flex border-[1px] border-white px-2 py-1 hover:bg-neutral-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="my-auto h-6 fill-white"
          >
            <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
          </svg>
        </Link>

        <Link
          to="/logout"
          className="my-auto ml-3 border-[1px] border-white px-2 py-1 hover:bg-neutral-500"
        >
          {config?.logoutText ?? "Logout"}
        </Link>
      </div>
    </div>
  );
}

Header.defaultProps = { className: undefined, config: {} };
