/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    owner
    message
    createdAt
    channel
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const commentByChannel = /* GraphQL */ `query CommentByChannel(
  $channel: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  commentByChannel(
    channel: $channel
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      message
      createdAt
      channel
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentByChannelQueryVariables,
  APITypes.CommentByChannelQuery
>;
export const getDataBlob = /* GraphQL */ `query GetDataBlob($id: ID!, $client: ID!) {
  getDataBlob(id: $id, client: $client) {
    id
    blob
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDataBlobQueryVariables,
  APITypes.GetDataBlobQuery
>;
export const listDataBlobs = /* GraphQL */ `query ListDataBlobs(
  $id: ID
  $client: ModelIDKeyConditionInput
  $filter: ModelDataBlobFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDataBlobs(
    id: $id
    client: $client
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      blob
      client
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataBlobsQueryVariables,
  APITypes.ListDataBlobsQuery
>;
export const getAppParameters = /* GraphQL */ `query GetAppParameters($key: String!, $client: ID!) {
  getAppParameters(key: $key, client: $client) {
    key
    value
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppParametersQueryVariables,
  APITypes.GetAppParametersQuery
>;
export const listAppParameters = /* GraphQL */ `query ListAppParameters(
  $key: String
  $client: ModelIDKeyConditionInput
  $filter: ModelAppParametersFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAppParameters(
    key: $key
    client: $client
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      key
      value
      client
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppParametersQueryVariables,
  APITypes.ListAppParametersQuery
>;
