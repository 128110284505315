/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateComment = /* GraphQL */ `subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
  onCreateComment(filter: $filter) {
    id
    owner
    message
    createdAt
    channel
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommentSubscriptionVariables,
  APITypes.OnCreateCommentSubscription
>;
export const onUpdateComment = /* GraphQL */ `subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
  onUpdateComment(filter: $filter) {
    id
    owner
    message
    createdAt
    channel
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommentSubscriptionVariables,
  APITypes.OnUpdateCommentSubscription
>;
export const onDeleteComment = /* GraphQL */ `subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
  onDeleteComment(filter: $filter) {
    id
    owner
    message
    createdAt
    channel
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommentSubscriptionVariables,
  APITypes.OnDeleteCommentSubscription
>;
export const onCreateDataBlob = /* GraphQL */ `subscription OnCreateDataBlob($filter: ModelSubscriptionDataBlobFilterInput) {
  onCreateDataBlob(filter: $filter) {
    id
    blob
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDataBlobSubscriptionVariables,
  APITypes.OnCreateDataBlobSubscription
>;
export const onUpdateDataBlob = /* GraphQL */ `subscription OnUpdateDataBlob($filter: ModelSubscriptionDataBlobFilterInput) {
  onUpdateDataBlob(filter: $filter) {
    id
    blob
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDataBlobSubscriptionVariables,
  APITypes.OnUpdateDataBlobSubscription
>;
export const onDeleteDataBlob = /* GraphQL */ `subscription OnDeleteDataBlob($filter: ModelSubscriptionDataBlobFilterInput) {
  onDeleteDataBlob(filter: $filter) {
    id
    blob
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDataBlobSubscriptionVariables,
  APITypes.OnDeleteDataBlobSubscription
>;
export const onCreateAppParameters = /* GraphQL */ `subscription OnCreateAppParameters(
  $filter: ModelSubscriptionAppParametersFilterInput
) {
  onCreateAppParameters(filter: $filter) {
    key
    value
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAppParametersSubscriptionVariables,
  APITypes.OnCreateAppParametersSubscription
>;
export const onUpdateAppParameters = /* GraphQL */ `subscription OnUpdateAppParameters(
  $filter: ModelSubscriptionAppParametersFilterInput
) {
  onUpdateAppParameters(filter: $filter) {
    key
    value
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAppParametersSubscriptionVariables,
  APITypes.OnUpdateAppParametersSubscription
>;
export const onDeleteAppParameters = /* GraphQL */ `subscription OnDeleteAppParameters(
  $filter: ModelSubscriptionAppParametersFilterInput
) {
  onDeleteAppParameters(filter: $filter) {
    key
    value
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAppParametersSubscriptionVariables,
  APITypes.OnDeleteAppParametersSubscription
>;
