import { getCurrentUser } from "aws-amplify/auth";

export async function isLoggedIn() {
  try {
    await getCurrentUser();
    return true;
  } catch {
    return false;
  }
}

export function test() {}
