import React, { useId } from "react";

export default function FormInput({
  disabled,
  label,
  type,
  name,
  className,
}: {
  disabled?: boolean;
  label?: string;
  type: string;
  name: string;
  className?: string;
}) {
  const id = useId();

  return (
    <label
      htmlFor={id}
      className={["my-5 flex w-full bg-primary text-lg", className].join(" ")}
    >
      <p className="py-2 pl-4 pr-2 text-secondary">{label}</p>
      <input
        type={type}
        name={name}
        id={id}
        className="w-full bg-primary p-2 outline-none"
        disabled={disabled}
      />
    </label>
  );
}

FormInput.defaultProps = {
  label: "",
  disabled: false,
  className: undefined,
};
