import { generateClient } from "aws-amplify/api";
import { useCallback, useState } from "react";
import { LoaderFunctionArgs, redirect, useLoaderData } from "react-router-dom";
import Comments from "../components/Comments";
import DataContextProvider from "../components/DataContextProvider";
import Format from "../components/Format";
import GraphRangeContextProvider from "../components/GraphRangeContextProvider";
import Header from "../components/Header";
import InspectionTable from "../components/InspectionTable";
import Table from "../components/Table";
import Tabs from "../components/Tabs";
import Text from "../components/Text";
import Graphs from "../components/graphs/Graphs";
import { listAppParameters } from "../graphql/queries";
import { onUpdateAppParameters } from "../graphql/subscriptions";
import { isLoggedIn } from "../utils/auth";

const API = generateClient();

const RESTART_TIMEOUT_S = 24 * 60 * 60;

type LoaderType = { client: string; config: any };
export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderType> {
  if (!(await isLoggedIn())) throw redirect("/login");

  const { data } = await API.graphql({
    query: listAppParameters,
    variables: {
      filter: {
        key: { eq: "config" },
        client: { eq: params.clientId },
      },
    },
  });

  if (data.listAppParameters.items.length <= 0)
    throw new Response("", {
      status: 404,
      statusText: "Client Not Found",
    });

  setTimeout(() => window.location.reload(), RESTART_TIMEOUT_S * 1000);

  API.graphql({
    query: onUpdateAppParameters,
    variables: {
      filter: {
        key: { eq: "restart" },
      },
    },
  }).subscribe({
    next({
      data: {
        onUpdateAppParameters: { client },
      },
    }) {
      if (client === params.clientId) window.location.reload();
    },
    error(err) {
      console.log("Failed to subscribe to restart: ", err); // eslint-disable-line no-console
    },
  });

  return {
    client: params.clientId as string,
    config: JSON.parse(data.listAppParameters.items[0].value),
  };
}

export default function OperatorApp() {
  const { client, config } = useLoaderData() as LoaderType;

  const [selectedInspectionTabIndex, setSelectedInspectionTabIndex] =
    useState(0);
  const [selectedInspectionElement, setSelectedInspectionElement] = useState<
    [string, number] | undefined
  >(undefined);

  const onElementSelected = useCallback((val: [string, number] | undefined) => {
    setSelectedInspectionElement(val);
    if (val) setSelectedInspectionTabIndex(1);
  }, []);

  return (
    <DataContextProvider config={config.dataBlobs} client={client}>
      <GraphRangeContextProvider>
        <Header config={config.header} />

        <div className="flex min-h-0 w-full flex-grow flex-wrap-reverse p-2">
          <div className="flex h-full w-full flex-col justify-around space-y-2 lg:w-2/3 lg:pr-1">
            {[config.line1, config.line2, config.line3].map((line, key) => (
              <Tabs
                key={key} // eslint-disable-line react/no-array-index-key
                className="h-1/3 min-h-0 flex-grow"
                idleTimeS={config.idleTimeS}
              >
                {(tab) =>
                  line.map((v: any) =>
                    tab(
                      v.tabName,
                      <div key={v.id} className="flex h-full w-full flex-col">
                        <h1 className="text-2xl">
                          <Format str={v.title} />

                          <span className="ml-1 text-sm">
                            <Format str={v.subtitle} />
                          </span>
                        </h1>

                        {v.graphs && (
                          <Graphs
                            onElementSelected={onElementSelected}
                            graphs={v.graphs}
                          />
                        )}
                      </div>,
                      v.rotateTimerSeconds
                    )
                  )
                }
              </Tabs>
            ))}
          </div>
          <div className="mb-2 flex w-full flex-col justify-between space-y-2 lg:m-0 lg:w-1/3 lg:pl-1 talllg:h-full">
            <Text config={config.alertText} />

            <Tabs
              selectedTab={selectedInspectionTabIndex}
              setSelectedTab={setSelectedInspectionTabIndex}
              className="min-h-0 flex-grow lg:max-h-[70vh] talllg:max-h-full"
            >
              {(tab) => [
                tab(
                  config.metrics.title,
                  <Table key="metrics" config={config.metrics} />
                ),
                config.inspectionTab?.enabled
                  ? tab(
                      config.inspectionTab.title ?? "Inspection Tab",
                      <InspectionTable
                        key="inspection"
                        selected={selectedInspectionElement}
                        config={config.inspectionTab}
                      />
                    )
                  : undefined,
              ]}
            </Tabs>

            <Tabs className="h-fit">
              {(tab) =>
                tab(
                  config.comments.title,
                  <Comments key="comments" config={config.comments} />
                )
              }
            </Tabs>
          </div>
        </div>
      </GraphRangeContextProvider>
    </DataContextProvider>
  );
}
