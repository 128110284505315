import { signOut } from "aws-amplify/auth";
import { redirect, useFetcher } from "react-router-dom";
import FormButton from "../../components/FormButton";

export async function action() {
  await signOut();
  // eslint-disable-next-line no-restricted-globals
  location.pathname = "/";
  return redirect("/");
}

export default function LogoutPage() {
  const fetcher = useFetcher();

  return (
    <>
      <h1 className="p-5 text-4xl">Log Out</h1>
      <p className="mx-5 mt-2 text-center">Are you sure you want to log out?</p>
      <fetcher.Form method="post" className="mt-7 flex w-full">
        <FormButton
          className="mx-auto w-1/2"
          label={fetcher.state !== "idle" ? "Loading" : "Log out"}
          disabled={fetcher.state !== "idle"}
        />
      </fetcher.Form>
    </>
  );
}
