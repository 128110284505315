import { confirmSignIn, signIn } from "aws-amplify/auth";
import {
  ActionFunctionArgs,
  Link,
  redirect,
  useFetcher,
} from "react-router-dom";
import FormButton from "../../components/FormButton";
import FormInput from "../../components/FormInput";

type ActionType = { error?: string; resetNeeded?: boolean };
export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionType | Response> {
  const fields = Object.fromEntries(await request.formData());

  if (typeof fields.email !== "string" || !fields.email.includes("@"))
    return { error: "Invalid Email" };

  if (typeof fields.password !== "string" || !fields.password)
    return { error: "Invalid Password" };

  try {
    const user = await signIn({
      username: fields.email,
      password: fields.password,
    });

    if (
      user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
    ) {
      if (typeof fields.newpassword !== "string" || !fields.newpassword)
        return { resetNeeded: true };

      await confirmSignIn({
        challengeResponse: fields.newpassword,
      });
    }

    return redirect("/");
  } catch (error: any) {
    return { error: error.message ?? "Unknown error" };
  }
}

export default function LoginPage() {
  const fetcher = useFetcher();
  const { error, resetNeeded = false } = (fetcher.data ?? {}) as ActionType;

  return (
    <>
      <h1 className="p-5 text-4xl">Sign In</h1>
      {resetNeeded && (
        <p className="mx-5 mt-2">
          Your temporary password could only be used once. Please set a new
          permanent password.
        </p>
      )}
      {typeof error === "string" && (
        <p className="mx-5 border-2 border-red-500 p-2">{error}</p>
      )}

      <fetcher.Form method="post">
        <FormInput label="Email" name="email" type="email" disabled={false} />

        <FormInput
          label="Password"
          name="password"
          type="password"
          disabled={false}
        />

        {resetNeeded && (
          <FormInput label="New Password" name="newpassword" type="password" />
        )}

        <div className="flex w-full">
          {!resetNeeded && (
            <Link
              to="/passwordreset"
              className="my-auto w-1/2 text-center text-secondary"
            >
              Forgot password?
            </Link>
          )}

          <FormButton
            className="w-1/2"
            label={fetcher.state !== "idle" ? "Loading" : "Login"}
            disabled={fetcher.state !== "idle"}
          />
        </div>
      </fetcher.Form>
    </>
  );
}
