import BillboardJS, { IChart } from "@billboard.js/react";
import bb, {
  ChartOptions,
  area,
  areaLineRange,
  areaStep,
  areaStepRange,
  line,
  step,
  subchart,
} from "billboard.js";
import "billboard.js/dist/billboard.css";
import { useContext, useEffect, useMemo, useRef } from "react";
import isValidNumber from "../../utils/isValidNumber";
import zip from "../../utils/zip";
import { dataContext } from "../DataContextProvider";
import "./graph.css";
import { GraphDataType, TimeseriesType } from "./types";

function getBacktestingGraphData(
  config: {
    timeSearch?: string;
    timeseries?: TimeseriesType[];
    inspection?: string;
  },
  getNumbersArray: (searchString: string) => number[] | undefined
) {
  const data: GraphDataType = {
    series: {},
    columns: [],
    types: {},
    colors: {},
  };

  if (config.timeSearch) {
    const rawTimes = getNumbersArray(config.timeSearch);
    if (rawTimes)
      data.series.time = rawTimes.map((time: number) => time * 1000);
  }

  if (config.timeseries) {
    config.timeseries.forEach((ts, tskey) => {
      const label = ts.label ?? `y-${tskey}`;

      switch (ts.type) {
        case "area-line-range":
        case "area-step-range": {
          const values = zip(
            ...ts.search
              .map((search) => getNumbersArray(search))
              .filter((item): item is number[] => !!item)
          )
            .map((item) => item.filter(isValidNumber))
            .map((item) => item.sort((a, b) => a - b))
            .map((item) => {
              if (item.length >= 3) return item;
              if (item.length >= 1) return [item[0], item[0], item[0]];
              return null;
            });

          data.series[label] = values;
          data.types[label] =
            ts.type === "area-line-range" ? areaLineRange() : areaStepRange();
          if (ts.color) data.colors[label] = ts.color;
          break;
        }

        case "area":
        case "area-step":
        case "line":
        case "step": {
          const search = getNumbersArray(ts.search);
          if (search) data.series[label] = search;

          area();
          areaStep();
          line();
          step();
          data.types[label] = ts.type;

          if (ts.color) data.colors[label] = ts.color;
          break;
        }

        default:
          break;
      }
    });
  }

  return data;
}

export default function BacktestingGraph({
  config,
  onElementSelected,
}: Readonly<{
  config: {
    timeSearch?: string;
    timeseries?: TimeseriesType[];
    inspection?: string;
    legend?: {
      display?: boolean;
      position?: "right" | "bottom" | "inset";
    };
  };
  onElementSelected: (val: [string, number] | undefined) => void;
}>) {
  const ref = useRef<IChart>(null);
  const { getNumbersArray } = useContext(dataContext);

  const data = useMemo(
    () => getBacktestingGraphData(config, getNumbersArray),
    [getNumbersArray, config]
  );

  useEffect(() => {
    const chart = ref.current?.instance;
    if (!chart) return;

    chart.load({
      json: data.series as any,
      types: data.types,
      unload: false,
      colors: data.colors,
      resizeAfter: true,
    });
  }, [data]);

  const options = useMemo(
    (): ChartOptions => ({
      area: {
        front: false,
      },
      point: {
        r(d) {
          return (
            config.timeseries?.find((ts) => ts.label === d.id)?.pointRadius ?? 3
          );
        },
      },
      data: {
        json: data.series,
        x: "time",
        types: data.types,
        colors: data.colors,
        onclick(d) {
          if (d.index && config.inspection)
            onElementSelected([config.inspection, d.index]);
        },
      },
      subchart: {
        show: subchart(),
        size: { height: 20 },
      },
      padding: {
        mode: "fit",
        right: 5,
      },
      legend: {
        show: config.legend?.display ?? true,
        position: config.legend?.position ?? "right",
      },
      grid: {
        x: {
          show: true,
        },
        y: {
          show: true,
        },
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: "%Y-%m-%d",
          },
        },
      },
      tooltip: {
        linked: true,
        grouped: true,
        format: {
          title(x) {
            const d = new Date(x);
            return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
          },
          value(value) {
            return value.toPrecision(3);
          },
        },
      },
    }),
    [
      onElementSelected,
      config.inspection,
      config.timeseries,
      config.legend,
      data,
    ]
  );

  return (
    <BillboardJS
      bb={bb}
      options={options}
      ref={ref}
      className="bb h-full w-full"
    />
  );
}
