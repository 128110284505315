/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    id
    owner
    message
    createdAt
    channel
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    id
    owner
    message
    createdAt
    channel
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $input: DeleteCommentInput!
  $condition: ModelCommentConditionInput
) {
  deleteComment(input: $input, condition: $condition) {
    id
    owner
    message
    createdAt
    channel
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createDataBlob = /* GraphQL */ `mutation CreateDataBlob(
  $input: CreateDataBlobInput!
  $condition: ModelDataBlobConditionInput
) {
  createDataBlob(input: $input, condition: $condition) {
    id
    blob
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDataBlobMutationVariables,
  APITypes.CreateDataBlobMutation
>;
export const updateDataBlob = /* GraphQL */ `mutation UpdateDataBlob(
  $input: UpdateDataBlobInput!
  $condition: ModelDataBlobConditionInput
) {
  updateDataBlob(input: $input, condition: $condition) {
    id
    blob
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataBlobMutationVariables,
  APITypes.UpdateDataBlobMutation
>;
export const deleteDataBlob = /* GraphQL */ `mutation DeleteDataBlob(
  $input: DeleteDataBlobInput!
  $condition: ModelDataBlobConditionInput
) {
  deleteDataBlob(input: $input, condition: $condition) {
    id
    blob
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDataBlobMutationVariables,
  APITypes.DeleteDataBlobMutation
>;
export const createAppParameters = /* GraphQL */ `mutation CreateAppParameters(
  $input: CreateAppParametersInput!
  $condition: ModelAppParametersConditionInput
) {
  createAppParameters(input: $input, condition: $condition) {
    key
    value
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAppParametersMutationVariables,
  APITypes.CreateAppParametersMutation
>;
export const updateAppParameters = /* GraphQL */ `mutation UpdateAppParameters(
  $input: UpdateAppParametersInput!
  $condition: ModelAppParametersConditionInput
) {
  updateAppParameters(input: $input, condition: $condition) {
    key
    value
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAppParametersMutationVariables,
  APITypes.UpdateAppParametersMutation
>;
export const deleteAppParameters = /* GraphQL */ `mutation DeleteAppParameters(
  $input: DeleteAppParametersInput!
  $condition: ModelAppParametersConditionInput
) {
  deleteAppParameters(input: $input, condition: $condition) {
    key
    value
    client
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAppParametersMutationVariables,
  APITypes.DeleteAppParametersMutation
>;
